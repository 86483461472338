import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, List, ListItem, ListItemText, Typography, ListItemIcon, Avatar, Button } from "@mui/material";
import { Email, Person, Badge, CalendarToday, Work, ErrorOutline } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { RootState } from "../../store";
import { getProfile } from "../../slices/userSlice";
import theme from "../../utils/theme";
import GoBackBtn from "../GoBackBtn";

interface ProfileResponseTypes {
  id: number;
  academic_year_id: number;
  created_at: string;
  email: string;
  name_en: string;
  name_my: string;
  national_id: number;
  principal_id: number;
  role: string[];
  updated_at: string;
  user_id: number;
}

const ProfileDetail: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const [profileData, setProfileData] = useState<ProfileResponseTypes | null>(null);
  const { loading, data, error } = useAppSelector((state: RootState) => state.user);

  // Fetch the profile if not already loaded
  useEffect(() => {
    if (!data && !error) {
      dispatch(getProfile());
    }
  }, [dispatch, data, error]);

  // Set the profile data when fetched
  useEffect(() => {
    if (data && data.data) {
      setProfileData(data.data);
    }
  }, [data]);

  // Retry fetching profile data in case of an error
  const handleRetry = () => {
    dispatch(getProfile());
  };

  return (
    <Grid item xs={12}>
      <Card
        elevation={0}
        sx={{
          borderRadius: "10px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent>
          {loading ? (
            <Typography variant="h6" color="textSecondary">
              Loading...
            </Typography>
          ) : error ? (
            <>
              <Grid container direction="column" alignItems="center" justifyContent="center">
                <Avatar sx={{ bgcolor: theme.palette.error.main, mb: 2 }}>
                  <ErrorOutline />
                </Avatar>
                <Typography variant="h6" color={theme.palette.error.main}>
                  Failed to load profile data
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRetry}
                  sx={{ mt: 2 }}
                >
                  Retry
                </Button>
              </Grid>
            </>
          ) : profileData ? (
            <>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                Profile Details
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: theme.palette.info.main }}>
                      <Person />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary="Name"
                    secondary={`${profileData.name_en} (${profileData.name_my})`}
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: theme.palette.success.light }}>
                      <Email />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary="Email" secondary={profileData.email} />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: theme.palette.warning.light }}>
                      <Badge />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary="National ID"
                    secondary={profileData.national_id}
                  />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: theme.palette.secondary.light }}>
                      <Work />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText primary="Role" secondary={profileData.role.join(", ")} />
                </ListItem>

                <ListItem>
                  <ListItemIcon>
                    <Avatar sx={{ bgcolor: theme.palette.warning.dark }}>
                      <CalendarToday />
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primary="Joined On"
                    secondary={new Date(profileData.created_at).toLocaleDateString()}
                  />
                </ListItem>
              </List>
            </>
          ) : (
            <Typography variant="h6" color="textSecondary">
              No Profile Data Available
            </Typography>
          )}
          <GoBackBtn/>
        </CardContent>
      </Card>
    </Grid>
  );
});

export default ProfileDetail;