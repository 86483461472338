import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getAuth, getHeaders } from "../utils/config";
import { BasicStateTypes, GradeRoomForSysSetting } from "../types";
import { showToast } from "../hooks/showToast";

interface SchoolStateType extends BasicStateTypes {
  gradeRooms: GradeRoomForSysSetting[];
}

const initialState: SchoolStateType = {
  loading: false,
  data: null,
  error: null,
  gradeRooms: [],
};

export interface SchoolPayload {
  id?: number;
  data?: any;
}

export interface GradeRoomPayload {
  id?: number;
  data?: any;
}

const buildUrl = (
  baseUrl: string,
  {
    id,
    action,
    pageNo,
    searchKeyword,
    schoolId,
    academicYearId,
  }: {
    id?: number;
    action: string;
    pageNo?: string | null;
    searchKeyword?: string | null;
    schoolId?: number;
    academicYearId?: number;
  }
): string => {
  const url = new URL(`${baseUrl}${id ? `/${id}` : ""}/${action}`);
  if (action === "all") url.searchParams.append("per_page", "10");
  if (searchKeyword) url.searchParams.append("search", searchKeyword);
  if (pageNo) url.searchParams.append("page", pageNo);
  if (schoolId) url.searchParams.append("school_id", schoolId.toString());
  if (academicYearId) url.searchParams.append("academic_year_id", academicYearId.toString());

  return url.toString();
};

export const schoolCrud = createAsyncThunk(
  "school/schoolCrud",
  async (
    { method, action, payload }: { method: string; action: string; payload?: SchoolPayload },
    { rejectWithValue }
  ) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");
      const searchKeyword = searchParams.get("keyword");

      const url = buildUrl(`${BASE_URL}/schools`, {
        id: payload?.id,
        action,
        pageNo,
        searchKeyword,
      });

      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);
      showToast(action);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const sysSettingGradeRoomCrud = createAsyncThunk(
  "school/sysSettingGradeRoomCrud",
  async (
    { method, action, payload }: { method: string; action: string; payload?: GradeRoomPayload },
    { rejectWithValue }
  ) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const schoolId = Number(searchParams.get("school_id")) || payload?.id;
      const defaultAcademicYear = getAuth()?.defaultAcademicYear?.id;
      const academicYearId = Number(searchParams.get("academic_year_id")) || defaultAcademicYear;

      const url = buildUrl(`${BASE_URL}/classes/grade-room`, {
        action,
        schoolId,
        academicYearId,
      });

      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);
      showToast(action);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

export const schoolSlice = createSlice({
  name: "school",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(schoolCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(schoolCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(schoolCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(sysSettingGradeRoomCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sysSettingGradeRoomCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.gradeRooms = action.payload.data;
      })
      .addCase(sysSettingGradeRoomCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default schoolSlice.reducer;
