import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { BasicStateTypes } from '../types';
import { BASE_URL, getHeaders } from '../utils/config';
import toast from 'react-hot-toast';

const initialState: BasicStateTypes = {
  loading: false,
  data: null,
  error: null,
};

export interface LoginPayload {
  email: string;
  password: string;
}

export interface ChangePasswordPayload {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}


export const login = createAsyncThunk<
  any, 
  LoginPayload, 
  { rejectValue: string }
>(
  'auth/login',
  async (payload, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/users/login`;
      const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: { email: payload.email, password: payload.password },
      };
      const response = await axios(config);
      toast.success('Successfully logged in.');
      return response.data;
    } catch (error: any) {
      if (error.response?.status === 401) {
        toast.error('Invalid email or password!');
      }
      return rejectWithValue(error.response?.data?.message || 'Login failed');
    }
  }
);

export const changePassword = createAsyncThunk<
  any, 
  ChangePasswordPayload, 
  { rejectValue: string }
>(
  'auth/changePassword',
  async (payload, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/users/change-password`;
      const config: AxiosRequestConfig = {
        method: 'POST',
        url,
        headers: getHeaders(),
        data: payload,
      };
      const response = await axios(config);
      toast.success('Password changed successfully.');
      return response.data;
    } catch (error: any) {
      if (error.response?.status === 422) {
        toast.error('Invalid current password!');
      }
      return rejectWithValue(error.response?.data?.message || 'Password change failed');
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changePassword.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
