import {
  createAsyncThunk,
  createSlice,
  nanoid,
  PayloadAction,
} from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import {
  AcademicClassElementType,
  AcademicClassSubjectType,
  AcademicYearType,
  ClassType,
  GradeResType,
  GradeRoomType,
} from "../types";
import { BASE_URL, getHeaders, removeAuth } from "../utils/config";
import toast from "react-hot-toast";

interface AcademicClassElementState {
  loading: boolean;
  data: any;
  error: string | null;
  rooms: AcademicClassElementType[];
  grades: GradeResType[];
  grade_rooms: GradeRoomType[];
  subjects: AcademicClassElementType[];
  academicYears: AcademicYearType[];
  classes_subjects: {
    id: string | number;
    gradeRoom: GradeRoomType;
    subject: AcademicClassSubjectType;
  }[];
  gradeToEdit: GradeResType | null;
}

const initialState: AcademicClassElementState = {
  loading: false,
  data: null,
  error: null,
  rooms: [],
  grades: [],
  grade_rooms: [],
  subjects: [],
  classes_subjects: [],
  gradeToEdit: null,
  academicYears: [],
};

export interface AcademicClassElementPayload {
  id?: number;
  data?: any;
}

interface ThunkParams {
  method: "GET" | "POST" | "PUT" | "DELETE";
  action: string;
  endPoint: string;
  payload?: AcademicClassElementPayload;
}

export const academicClassElementCrud = createAsyncThunk<
  any,
  ThunkParams,
  { rejectValue: string }
>(
  "academicClassElement/academicClassElementCrud",
  async ({ method, action, endPoint, payload }, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/${endPoint}${
        payload?.id ? `/${payload.id}` : ""
      }/${action}`;
      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };
      const response = await axios(config);

      if (action === "create") toast.success("Successfully created", { duration: 3000 });
      if (action === "update") toast.success("Successfully updated", { duration: 3000 });
      if (action === "delete") toast.success("Successfully deleted", { duration: 3000 });

      return response.data;
    } catch (error: any) {
      if (error.response?.status === 401) {
        toast.error("Session expired!");
        removeAuth();
        window.location.reload();
      } else if (error.response?.status === 422) {
        toast.error("The name has already been taken.");
      }

      return rejectWithValue(error.response?.data?.message || "Something went wrong");
    }
  }
);

export const academicClassElementSlice = createSlice({
  name: "academicClassElement",
  initialState,
  reducers: {
    setGradeToEdit: (state, action: PayloadAction<GradeResType | null>) => {
      state.gradeToEdit = action.payload;
    },
    resetClassElement: (state) => {
      state.classes_subjects = [];
    },
    replaceClassElement: (state, action: PayloadAction<ClassType[]>) => {
      state.classes_subjects = action.payload;
    },
    addClassElement: (
      state,
      action: PayloadAction<{
        gradeRoom: GradeRoomType;
        subject: AcademicClassSubjectType;
      }>
    ) => {
      const { gradeRoom, subject } = action.payload;
      const exists = state.classes_subjects.some(
        (element) =>
          element.gradeRoom.id === gradeRoom.id &&
          element.subject.id === subject.id
      );

      if (!exists) {
        state.classes_subjects.push({ id: nanoid(), ...action.payload });
      } else {
        alert("This class and subject already exist.");
      }
    },
    removeClassElement: (
      state,
      action: PayloadAction<{ id: string | number }>
    ) => {
      state.classes_subjects = state.classes_subjects.filter(
        (classElement) => classElement.id !== action.payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(academicClassElementCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(academicClassElementCrud.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;

        // Handle specific endpoints
        const { endPoint } = action.meta.arg;
        if (endPoint === "classes/grades") {
          state.grades = action.payload.data;
        } else if (endPoint === "classes/rooms") {
          state.rooms = action.payload.data;
        } else if (endPoint === "classes/grade-room") {
          state.grade_rooms = action.payload.data;
        } else if (endPoint === "subjects" && !action.meta.arg.action.includes("all?grade_id")) {
          state.subjects = action.payload?.data || [];
        } else if (endPoint === "academic-years") {
          state.academicYears = action.payload?.data || [];
        }
      })
      .addCase(academicClassElementCrud.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch data";
      });
  },
});

export const {
  setGradeToEdit,
  addClassElement,
  removeClassElement,
  resetClassElement,
  replaceClassElement,
} = academicClassElementSlice.actions;

export default academicClassElementSlice.reducer;