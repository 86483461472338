import { Box } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { BeatLoader } from 'react-spinners';
import theme from '../utils/theme';

const Loading: React.FC = () => {
    const loadingContainer = document.getElementById('loading-portal');

    if (!loadingContainer) {
        return null;
    }

    return ReactDOM.createPortal(
        <Box sx={{
            height: '100vh',
            display: 'flex',
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            zIndex: 2000,
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}>
            <BeatLoader color={theme.palette.primary.main} />
        </Box>,
        loadingContainer
    );
}

export default Loading;