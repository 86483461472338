import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL, getHeaders } from '../utils/config';
import { BasicStateTypes } from '../types';

const initialState: BasicStateTypes = {
  loading: false,
  data: null,
  error: null,
};

export const fetchImageAsBase64 = createAsyncThunk<string, string, { rejectValue: string }>(
  'getPhoto/fetchImageAsBase64',
  async (fileName: string, { rejectWithValue }) => {
    try {
      const headers = getHeaders();
      const response = await axios.get(`${BASE_URL}/file/${fileName}`, {
        headers,
        responseType: 'blob',
      });
      const imageBlob = response.data;

      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve(reader.result as string);
          } else {
            reject(new Error('Failed to convert image to data URL'));
          }
        };
        reader.onerror = () => {
          reject(new Error('Failed to read file'));
        };
        reader.readAsDataURL(imageBlob);
      });
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch image');
    }
  }
);
const fetchImageAsBase64Slice = createSlice({
  name: 'getPhoto',
  initialState,
  reducers: {
    resetPhotoState: (state) => {
      state.loading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchImageAsBase64.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchImageAsBase64.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchImageAsBase64.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetPhotoState } = fetchImageAsBase64Slice.actions;
export default fetchImageAsBase64Slice.reducer;
