import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import photoSlice from './slices/photoSlice'
import studentSlice from './slices/studentSlice'
import teacherSlice from './slices/teacherSlice'
import academicClassElementSlice from './slices/academicClassElementSlice'
import fetchImageAsBase64Slice from './slices/fetchImageAsBase64Slice'
import userSlice from './slices/userSlice'
import principalSlice from './slices/principalSlice'
import fileUploadSlice from './slices/fileUploadSlice'
import dashboardSlice from './slices/dashboardSlice'
import schoolSlice from './slices/schoolSlice'
import manageGradeSlice from './slices/manageGradeSlice'
import markingSchemeSlice from './slices/markingSchemeSlice'

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    school: schoolSlice,
    photo: photoSlice,
    file: fileUploadSlice,
    dashboardData: dashboardSlice,
    photoString: fetchImageAsBase64Slice,
    student: studentSlice,
    teacher: teacherSlice,
    principal: principalSlice,
    academicClassElement: academicClassElementSlice,
    gradeManagement: manageGradeSlice,
    markingScheme: markingSchemeSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch