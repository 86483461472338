import { Provider } from 'react-redux';
import './App.css';
import { store } from './store';
import AppRouter from './routes/AppRouter';
import { ThemeProvider } from '@emotion/react';
import theme from './utils/theme';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRouter/>
        <Toaster/>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
