import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BasicStateTypes, SiblingType, SuccessHistoryType } from "../types";
import { BASE_URL, getAuth, getHeaders } from "../utils/config";
import { showToast } from "../hooks/showToast";

interface StudentStateType extends BasicStateTypes {
  nameList: SiblingType[];
  successHistories: SuccessHistoryType[];
}

const initialState: StudentStateType = {
  loading: false,
  data: null,
  error: null,
  successHistories: [],
  nameList: [],
};

export interface StudentPayload {
  id?: number;
  data?: any;
}

const buildUrl = (
  baseUrl: string,
  {
    id,
    action,
    pageNo,
    classGradeId,
    classRoomId,
    schoolId,
    searchKeyword,
    academicYearId,
  }: {
    id?: number;
    action: string;
    pageNo?: string | null;
    classGradeId?: string | null;
    classRoomId?: string | null;
    schoolId?: string | null;
    searchKeyword?: string | null;
    academicYearId?: number;
  }
): string => {
  const url = new URL(`${baseUrl}/students${id ? `/${id}` : ""}/${action}`);
  if (action === "all") url.searchParams.append("per_page", "10");
  if (classGradeId) url.searchParams.append("class_grade_id", classGradeId);
  if (classRoomId) url.searchParams.append("class_room_id", classRoomId);
  if (schoolId) url.searchParams.append("school_id", schoolId);
  if (searchKeyword) url.searchParams.append("search", searchKeyword);
  if (academicYearId) url.searchParams.append("academic_year_id", academicYearId.toString());
  if (pageNo) url.searchParams.append("page", pageNo);

  return url.toString();
};

export const studentCrud = createAsyncThunk(
  "student/studentCrud",
  async (
    {
      method,
      action,
      payload,
    }: { method: string; action: string; payload?: StudentPayload },
    { rejectWithValue }
  ) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");
      const classGradeId = searchParams.get("class_grade_id");
      const classRoomId = searchParams.get("class_room_id");
      const schoolId = searchParams.get("school_id");
      const searchKeyword = searchParams.get("keyword");
      const defaultAcademicYear = getAuth()?.defaultAcademicYear?.id;
      const academicYearId = Number(searchParams.get("academic_year_id")) || defaultAcademicYear;

      const url = buildUrl(BASE_URL, {
        pageNo,
        id: payload?.id,
        action,
        classGradeId,
        classRoomId,
        schoolId,
        searchKeyword,
        academicYearId,
      });

      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);
      showToast(action);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message || 'An error occurred');
    }
  }
);

export const getSiblings = createAsyncThunk(
  "student/getSiblings",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/students/name-lists`;
      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method: "GET",
        url,
        headers,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || error.message || 'An error occurred');
    }
  }
);

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    replaceHistories: (state, action: PayloadAction<SuccessHistoryType[]>) => {
      state.successHistories = action.payload;
    },
    addHistory: (state, action: PayloadAction<SuccessHistoryType>) => {
      state.successHistories.push(action.payload);
    },
    removeHistory: (state, action: PayloadAction<{ id: string | number }>) => {
      state.successHistories = state.successHistories.filter(
        (history) => history.id !== action.payload.id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(studentCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(studentCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(studentCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSiblings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSiblings.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.nameList = action.payload?.data || [];
      })
      .addCase(getSiblings.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { addHistory, replaceHistories, removeHistory } = studentSlice.actions;

export default studentSlice.reducer;
