import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getHeaders } from "../utils/config";
import { BasicStateTypes, CreateUserType } from "../types";
import toast from "react-hot-toast";

const initialState: BasicStateTypes = {
  loading: false,
  data: null,
  error: null,
};

interface ThunkParams {
  method: "GET" | "POST" | "PUT" | "DELETE";
  endPoint: string;
  payload?: CreateUserType;
}

export const userCrud = createAsyncThunk(
  "user/userCrud",
  async ({ method, endPoint, payload }: ThunkParams, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/${endPoint}`;
      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload || {},
      };

      const response = await axios(config);
      const successMessage =
        method === "POST"
          ? "Successfully created"
          : method === "PUT"
          ? "Successfully updated"
          : method === "DELETE"
          ? "Successfully deleted"
          : "Operation successful";

      toast.success(successMessage, { duration: 3000 });

      return response.data;
    } catch (error: any) {
      toast.error("Operation failed", { duration: 3000 });
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/users/profile`;
      const headers = getHeaders();
      const method = "GET";
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: null,
      };

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      toast.error("Failed to fetch profile", { duration: 3000 });
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(userCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getProfile.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
