import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getHeaders } from "../utils/config";
import { BasicStateTypes, PrincipalType } from "../types";
import toast from "react-hot-toast";

const initialState: BasicStateTypes = {
  loading: false,
  data: null,
  error: null,
};

export interface PrincipalPayload {
  id?: number;
  data?: PrincipalType;
}

interface ThunkParams {
  method: "GET" | "POST" | "PUT" | "DELETE";
  action: string;
  payload?: PrincipalPayload;
}

const buildUrl = (
  baseUrl: string,
  { action, id, pageNo }: { action: string; id?: number; pageNo?: string | null }
): string => {
  const url = new URL(`${baseUrl}/principals${id ? `/${id}` : ""}/${action}`);
  if (action === "all") url.searchParams.append("per_page", "10"); // Set pagination
  if (pageNo) url.searchParams.append("page", pageNo);
  return url.toString();
};

export const principalCrud = createAsyncThunk(
  "principal/principalCrud",
  async ({ method, action, payload }: ThunkParams, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");
      
      const url = buildUrl(BASE_URL, { pageNo, id: payload?.id, action });
      const headers = getHeaders();
      
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);

      if (action === "create") toast.success("Successfully created", { duration: 3000 });
      if (action === "delete") toast.success("Successfully deleted", { duration: 3000 });
      if (action === "update") toast.success("Successfully updated", { duration: 3000 });

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);


export const principalSlice = createSlice({
  name: "principal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(principalCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(principalCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(principalCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default principalSlice.reducer;
