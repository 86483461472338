import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { BasicStateTypes, PhotoType } from '../types';
import { BASE_URL, getHeaders } from '../utils/config';
import toast from 'react-hot-toast';

interface PhotoCrudParams {
  method: 'POST' | 'PUT' | 'DELETE';
  payload?: PhotoType;
}

interface PhotoState extends BasicStateTypes {
  data: any | null;
}

const initialState: PhotoState = {
  loading: false,
  data: null,
  error: null,
};

export const photoCrud = createAsyncThunk(
  'photo/photoCrud',
  async ({ method, payload }: PhotoCrudParams, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/file/upload`;
      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload,
      };

      const response = await axios(config);

      if (method === 'POST') toast.success('Successfully uploaded');
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const photoSlice = createSlice({
  name: 'photo',
  initialState,
  reducers: {
    resetPhotoData: (state) => {
      state.loading = false;
      state.data = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(photoCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(photoCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(photoCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetPhotoData } = photoSlice.actions;

export default photoSlice.reducer;
