import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#d90368',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          // padding: '10px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        onWheel: (event) => {
          (event.target as HTMLElement).blur();
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        onWheel: (event) => {
          (event.target as HTMLElement).blur();
        },
      },
    },
  },
});

export default theme;
