import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL, getAuth, getHeaders } from "../utils/config";
import { BasicStateTypes, MarkingSchemeType } from "../types";
import toast from "react-hot-toast";

const initialState: BasicStateTypes = {
  loading: false,
  data: null,
  error: null,
};

export interface MarkingSchemePayload {
  id?: number;
  data?: MarkingSchemeType;
}

interface ThunkParams {
  method: "GET" | "POST" | "PUT" | "DELETE";
  action: string;
  payload?: MarkingSchemePayload;
}

const buildUrl = (
  baseUrl: string,
  { id, action, pageNo, academicYearId }: { 
    id?: number; 
    action: string; 
    pageNo?: string | null; 
    academicYearId?: number; 
  }
): string => {
  const url = new URL(`${baseUrl}/markingschemes${id ? `/${id}` : ""}/${action}`);
  if (action === "all") url.searchParams.append("per_page", "10");
  if (pageNo) url.searchParams.append("page", pageNo);
  if (academicYearId) url.searchParams.append("academic_year_id", academicYearId.toString());

  return url.toString();
};

export const markingSchemeCrud = createAsyncThunk(
  "markingScheme/markingSchemeCrud",
  async ({ method, action, payload }: ThunkParams, { rejectWithValue }) => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const pageNo = searchParams.get("page");
      const defaultAcademicYear = getAuth()?.defaultAcademicYear?.id;
      const academicYearId = Number(searchParams.get("academic_year_id")) || defaultAcademicYear;

      const url = buildUrl(BASE_URL, { pageNo, id: payload?.id, action, academicYearId });
      const headers = getHeaders();

      const config: AxiosRequestConfig = {
        method,
        url,
        headers,
        data: payload?.data || {},
      };

      const response = await axios(config);
      if (action === "delete") toast.success("Successfully deleted", { duration: 3000 });

      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || "An error occurred";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const markingSchemeSlice = createSlice({
  name: "markingScheme",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(markingSchemeCrud.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(markingSchemeCrud.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(markingSchemeCrud.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default markingSchemeSlice.reducer;
