import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL, getHeaders } from '../utils/config';
import { BasicStateTypes } from '../types';

interface DashboardState extends BasicStateTypes {
  data: any | null;
}

const initialState: DashboardState = {
  loading: false,
  data: null,
  error: null,
};

export const getDashboardData = createAsyncThunk<
  any, 
  void, 
  { rejectValue: string }
>(
  'dashboard/getDashboardData',
  async (_, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/dashboard/all`;
      const headers = getHeaders();
      const config: AxiosRequestConfig = {
        method: 'GET',
        url,
        headers,
      };
      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch dashboard data');
    }
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDashboardData.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dashboardSlice.reducer;
