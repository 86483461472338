import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosRequestConfig } from 'axios';
import { BasicStateTypes } from '../types';
import { BASE_URL, getHeaders } from '../utils/config';

// Initial state definition
const initialState: BasicStateTypes = {
  data: null,
  loading: false,
  error: null,
};

// Payload and ThunkParams interfaces
interface Payload {
  data: any;
}

interface ThunkParams {
  method: 'POST';
  type: string;
  payload?: Payload;
}


export const gradeManage = createAsyncThunk<
  any,
  ThunkParams,
  { rejectValue: string }
>(
  'gradeManagement/gradeManage',
  async ({ method, type, payload }: ThunkParams, { rejectWithValue }) => {
    try {
      const url = `${BASE_URL}/classes/${type}/manage`;
      const headers = getHeaders();

      const config: AxiosRequestConfig = {
        method,
        url,
        data: payload?.data,
        headers,
      };

      const response = await axios(config);
      return response.data;
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || error.message || 'An error occurred';
      return rejectWithValue(errorMessage);
    }
  }
);

const manageGradeSlice = createSlice({
  name: 'gradeManagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(gradeManage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(gradeManage.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(gradeManage.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default manageGradeSlice.reducer;